.hamburger {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: #ffffff80;
  transform: translateX(100%);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 9999;

  &.open {
    transition: 0.3s;
    transform: translateX(0);
  }

  &__content {
    position: relative;
    width: 300px;
    height: 100%;
    background-color: #F0F0F0;
    padding: 20px 0 40px 20px;
    overflow-y: scroll;
  }

  &__title {
    color: #585858;
    font-size: 3.0rem;
    font-family: 'Futura Medium Italic';
    margin-left: 50px;
    text-shadow: 0px 3px 6px #00000029;
  }

  &__close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 20px;
    top: 30px;
  }

  &__items {
    margin-top: 23px;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 46px;
  }
}