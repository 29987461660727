.business {
  width: 100%;
  padding: 20px 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  &__image {
    width: 30%;
  }

  &__content {
    width: calc(70% - 20px);
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__text {
    width: 100%;
    color: #585858;
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__button {
    position: relative;
    width: 30%;
    max-width: 300px;
    background-color: #F7F7F7;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'Futura Medium Italic';
    font-size: 1.5rem;
    color: #585858;
    padding: 5px 40px 5px 20px;
    margin-left: auto;
    margin-top: auto;
    text-align: center;
    border-radius: 20px;
    text-decoration: none;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      right: 10px;
      width: 26px;
      height: 26px;
      background-image: url('./img/button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover {
      background-color: #dfdede;
      transition: 0.3s;
    }
  }
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .business {
    &__image {
      width: 100%;
      max-width: 300px;
      margin: auto;
    }

    &__content {
      width: calc(100% - 320px);
      min-width: 300px;
    }

    &__button {
      min-width: 200px;
    }
  }
}