.contact {
  width: 100%;

  &__title {
    width: 70%;
    margin-top: 80px;
  }

  &__content {
    width: 100%;
    background-image: url('./img/contact.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    &__overlay {
      width: 60%;
      background-color: rgba(255, 255, 255, 0.84);
      padding: 50px 20px 80px 40px;
    }
  }

  &__text {
    color: #585858;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0.5rem;
    margin-top: 40px;
  }

  &__button {
    position: relative;
    display: inline-block;
    width: 80%;
    background-color: #F7F7F7;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'Futura Medium Italic';
    font-size: 1.5rem;
    color: #585858;
    padding: 10px 0 10px 40px;
    margin-top: 20px;
    text-align: center;
    border-radius: 20px;
    text-decoration: none;
    letter-spacing: 0.5rem;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      left: 30px;
      width: 26px;
      height: 26px;
      background-image: url('./img/button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover {
      background-color: #dfdede;
      transition: 0.3s;
    }
  }
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .contact {
    &__title {
      width: 100%;
      margin-top: 40px;
    }

    &__content {
      margin-top: 10px;
  
      &__overlay {
        width: 65%;
        padding: 20px 10px;
      }
    }
  
    &__text {
      font-size: 1.0rem;
      letter-spacing: 0;
      margin-top: 10px;
    }
  
    &__button {
      width: 90%;
      font-size: 1.1rem;
      color: #585858;
      padding: 5px 0 5px 30px;
      margin-top: 5px;
      border-radius: 10px;
      letter-spacing: 0;
      text-align: left;
  
      &::before {
        content: '';
        position: absolute;
        left: 10px;
        top: 7px;
        width: 13px;
        height: 13px;
      }
    }
  }
}