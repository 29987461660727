.pc .company {
  width: 100%;

  &__content {
    position: relative;
    width: calc(100% - 40px);
    background-image: url('./img/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 30px;
    margin: 20px;
    padding: 20px 20px 40px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__item {
    width: calc(100% - 100px);
    background-color: rgba(244, 248, 255, 0.77);
    border-radius: 15px;
    color: #393939;
    font-size: 1.3rem;
    font-weight: bold;
    padding: 5px 20px;
    display: flex;
    justify-content: flex-start;

    &__header {
      width: 100px;
    }
  }

  &__button {
    position: absolute;
    bottom: -15px;
    right: 0;
    width: 40%;
    min-width: 230px;
    max-width: 300px;
    background-color: #F7F7F7;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'Futura Medium Italic';
    font-size: 1.5rem;
    color: #585858;
    padding: 5px 40px 5px 20px;
    margin-left: auto;
    margin-top: auto;
    text-align: center;
    border-radius: 20px;
    text-decoration: none;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      right: 10px;
      width: 26px;
      height: 26px;
      background-image: url('./img/button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover {
      background-color: #dfdede;
      transition: 0.3s;
    }
  }
}