.pc .header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1440px;
  height: 10vh;
  min-height: 78px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 1px solid #ece8e8;
  z-index: 9999;

  &__logo{
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__icon {
    width: 8%;
    max-width: 42px;
  }

  &__main-text {
    min-width: 170px;
    margin-left: 2%;
    font-size: 2.0rem;
    font-weight: bold;
    color: #484848;
  }

  &__sub-text {
    width: 175px;
    min-width: 175px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 2%;

    &--name {
      width: 100%;
      color: #484848;
      font-size: 1.2rem;
    }

    &--caption {
      width: 100%;
      color: #484848;
      font-size: 1.0rem;
    }
  }

  &__items {
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__item {
    &__wrapper {
      margin-left: 2%;
      min-width: 80px;
    }
  }
}

// 1440px〜
@media screen and (min-width:1440px) {
  .pc .header {
    left: auto;
  }
}
