.video {
  position: relative;
  width: 100%;

  &__content {
    width: 100%;
  }

  &__overlay {
    position: absolute;
    width: 50%;
    top: 0;
    left: 0;
    padding: min(15vh, 100px) 5% 0;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
  }

  &__thankyou {
    width: 100%;
    margin-bottom: 80px;
  }

  &__text {
    width: 80%;
    min-width: 400px;
    font-weight: bold;
    color: #fff;
    margin-top: 20px;

    &--main {
      font-size: 2rem;
    }
  
    &--name {
      margin-top: 40px;
      font-size: 1.5rem;
      text-align: end;
    }
  }
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .video {
    &__overlay {
      padding: 10% 10px;
    }

    &__thankyou {
      margin-bottom: 15px;
    }
  
    &__text {
      width: 100%;
      margin-top: 5px;
      min-width: 50%;
  
      &--main {
        font-size: 3vw;
      }
    
      &--name {
        margin-top: 10px;
        font-size: 2vw;
      }
    }
  }
}