.overview {
  width: 100%;

  &__title {
    width: 50%;
  }

  &__line {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-left: 5px solid #e4e3e3;
    margin: 20px 20px 0 20px;
    padding-left: 10px;
    font-size: 1.3rem;

    &__header {
      min-width: 100px;
    }

    &__text {
      &--double {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 60px;
      }
    }
  }
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .overview {
    &__title {
      width: 100%;
    }

    &__line {
      margin: 10px 10px 0 10px;

      &__text {
        &--double {
          gap: 0;
        }
      }
    }
  }
}