.history {
  &__title {
    width: 50%;
    margin-top: 40px;
  }

  &__content {
    padding-left: 20px;
  }

  &__line {
    position: relative;
    font-size: 1.3rem;
    padding-top: 20px;
    padding-left: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &::before {
      content: '◯';
      position: absolute;
      left: 0;
      top: 20px;
      font-size: 10px;
      line-height: 1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 30px;
      left: 4.5px;
      width: 0.5px;
      height: calc(100% - 9px);
      background: #707070;
    }

    &:last-of-type {
      &::before {
        content: '●';
        color: #707070;
      }
      &::after {
        display: none;
      }
    }

    &__header {
      min-width: 150px;
    }
  }
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .history {
    &__title {
      width: 100%;
    }

    &__content {
      padding-left: 10px;
    }

    &__line {
      padding-top: 15px;
      padding-left: 20px;
      align-items: flex-start;

      &__header {
        min-width: 100px;
      }
    }
  }
}