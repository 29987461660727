@font-face {
	font-family: 'Futura Medium Italic';
	src: url('./fonts/Futura Medium Italic font.ttf');
}

@font-face {
	font-family: 'Futura Medium';
	src: url('./fonts/futura medium bt.ttf');
}

@font-face {
  font-family: 'ZenKakuGothicNew-Medium';
  src: url('./fonts/ZenKakuGothicNew-Medium.ttf');
}