.recruit {
  width: 100%;

  &__catch {
    color: #FF0000;
    line-height: 2rem;
    font-size: 2rem;
    font-weight: bold;
    text-shadow: 0px 3px 6px #00000029;
    margin-top: 20px;
    padding-left: 10px;
  }

  &__list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: wrap;
    color: #585858;
    font-size: 1.3rem;
    margin-top: 10px;
    padding-left: 20px;

    &__item {
      &::before {
        content: '●';
        margin-right: 10px;
      }
    }
  }

  &__image {
    border-radius: 30px;
    width: calc(100% - 40px);
    height: 250px;
    margin: 5px 20px 0;
    background-image: url('./img/driver.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__notice {
    color: #585858;
    font-size: 1.3rem;
    margin-top: 10px;
    padding-left: 20px;
  }
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .recruit {
    &__catch {
      font-size: 2.5rem;
      padding-left: 40px;
    }

    &__list {
      padding-left: 60px;
      &__item {
        width: 100%;
      }
    }

    &__notice {
      position: relative;
      font-size: 1.4rem;
      width: 100%;
      text-align: center;
      margin-bottom: 40px;

      &::after {
        position: absolute;
        left: calc(50% - 20px);
        top: 20px;
        content: '';
        width: 40px;
        height: 40px;
        background-image: url('./img/triangle.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}