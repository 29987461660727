.hub {
  &__title {
    width: 50%;
    margin-top: 40px;
  }

  &__content {
    padding-left: 160px;
    margin-top: 40px;
    font-size: 1.3rem;
    background-position: 40px 0;
    background-repeat: no-repeat;
    background-size: 60px 60px;

    &--house {
      background-image: url('./img/hub-house.png');
    }

    &--truck {
      background-image: url('./img/hub-truck.png');
    }

    &--building {
      background-image: url('./img/hub-building.png');
    }

    &__fax {
      margin-left: 30px;
    }
  }
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .hub {
    &__title {
      width: 100%;
    }

    &__content {
      padding-left: 80px;
      margin-top: 20px;
      background-position: 20px 5px;
      background-size: 40px 40px;
    }
  }
}