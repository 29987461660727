.sp .company {
  width: 100%;

  &__title {
    width: 100%;
    margin-top: 40px;
  }

  &__image {
    width: 100%;
    max-width: 700px;
    margin: 10px auto 0;
  }

  &__content {
    position: relative;
    width: 100%;
    margin-top: 20px;
    padding: 0 0 80px 40px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__item {
    width: 100%;
    background-color: #F4F8FF;
    color: #000;
    font-size: 1.3rem;
    padding: 5px 20px;
    display: flex;
    justify-content: flex-start;

    &__header {
      width: 80px;
    }
  }

  &__button {
    position: absolute;
    bottom: 35px;
    right: 40px;
    width: 40%;
    min-width: 230px;
    max-width: 300px;
    background-color: #F7F7F7;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'Futura Medium Italic';
    font-size: 1.5rem;
    color: #585858;
    padding: 5px 40px 5px 20px;
    margin-left: auto;
    margin-top: auto;
    text-align: center;
    border-radius: 20px;
    text-decoration: none;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      right: 10px;
      width: 26px;
      height: 26px;
      background-image: url('./img/button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &:hover {
      background-color: #dfdede;
      transition: 0.3s;
    }
  }
}