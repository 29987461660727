.wrapper {
  max-width: 1440px;
  min-height: 100vh;
  margin: auto;
  position: relative;
  box-shadow: 10px 0px 10px -10px #45454524, -10px 0px 10px -10px #45454524;
  overflow-x: hidden;

  &__title {
    width: 50%;
  }

  &__card {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px 20px 100px;
  }
}

.dummy {
  width: 330px;
  height: 0;
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .pc {
    display: none;
  }

  .wrapper {
    margin-top: 52px;

    &__title {
      width: 100%;
    }

    &__card {
      width: 100%;
      padding: 20px 0 0;
      gap: 40px;
    }
  }
}

// 1024px〜
@media only screen and (min-width: 1024px) {
  .sp {
    display: none;
  }

  .wrapper {
    margin-top: 10vh;
  }
}