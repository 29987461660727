.wrapper {
  max-width: 1440px;
  min-height: 100vh;
  margin: auto;
  position: relative;
  box-shadow: 10px 0px 10px -10px #45454524, -10px 0px 10px -10px #45454524;
  overflow-x: hidden;

  &__columns {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 80px;
  }

  &__column {
    width: 50%;
  }
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .pc {
    display: none;
  }

  .wrapper {
    margin-top: 52px;
  }
}

// 1024px〜
@media only screen and (min-width: 1024px) {
  .sp {
    display: none;
  }

  .wrapper {
    margin-top: 10vh;
  }
}