.title {
  width: 100%;
  height: 7vh;
  max-height: 50px;
  min-height: 30px;
  background-color: #F7F7F7;
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  &__text {
    color: #585858;
    font-size: 1.5rem;
    font-weight: bold;

    &--en {
      font-family: 'Futura Medium';
    }
  }

  &__slash {
    position: absolute;
    transform: skewX(-45deg);
    width: 20px;
    height: 100%;
    background-color:#fff;
    
  }

  & &__slash {
    &:nth-of-type(4) {
      right: 104px;
    }

    &:nth-of-type(5) {
      right: 64px;
    }

    &:last-of-type {
      right: -23px;
      width: 63px;
    }
  }
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .title {
    &__text {
      font-size: 1.5rem;
    }
  }
}