.resource {
  margin-bottom: 80px;

  &__title {
    width: 50%;
    margin-top: 40px;
  }

  &__content {
    padding-left: 180px;
    font-size: 1.3rem;
    background-position: 40px 20px;
    background-repeat: no-repeat;
    background-size: auto 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    &--big {
      background-image: url('./img/truck-big.png');
    }

    &--middle {
      background-image: url('./img/truck-middle.png');
    }

    &--small {
      background-image: url('./img/truck-small.png');
    }

    &--lift {
      background-image: url('./img/truck-lift.png');
    }

    &__text {
      width: 100%;
      height: 20px;
      margin-top: 20px;
    }
  }
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .resource {
    &__title {
      width: 100%;
    }

    &__content {
      padding-left: 120px;
      margin-top: 0;
      background-position: 20px 15px;
      background-size: auto 30px;

      &__text {
        margin-top: 10px;
      }
    }
  }
}