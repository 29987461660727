.sp .header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 9999;
  border-bottom: 1px solid #ece8e8;

  &__logo{
    width: calc(100% - 50px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__icon {
    width: 32px;
  }

  &__main-text {
    min-width: 145px;
    margin-left: 10px;
    font-size: 1.9rem;
    font-weight: bold;
    font-family: 'Futura Medium';
    color: #484848;
  }

  &__sub-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 10px;

    &--name {
      color: #484848;
      font-size: 1.0rem;
      width: 100%;
    }

    &--caption {
      color: #484848;
      font-size: 0.8rem;
    }
  }

  &__hamburger {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 5px;
  
    &__line {
      width: 100%;
      height: 5px;
      border-radius: 2.5px;
      background-color: #207FFF;
    }
  }
}