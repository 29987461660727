.header-item {
  &__link {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    text-decoration: none;

    &:visited {
      color: #585858;
    }

    &:hover {
      &::before {
        transition: 0.3s;
        background-color: #3b8af883;
      }
    }

    &::before {
      position: absolute;
      left: -15px;
      content: '';
      transform: skewX(-22.5deg);
      width: 5px;
      height: 100%;
      margin: 0 auto;
      background-color:#3B8BF8;
    }
  }

  &__ja {
    width: 100%;
    color: #585858;
    font-size: 1.2rem;
    margin-left: 5px;
  }

  &__en {
    position: relative;
    width: 100%;
    color: #585858;
    font-size: 0.8rem;
    font-family: 'Futura Medium Italic';
    margin-top: 10px;
    margin-left: 15px;

    &::before {
      content: '/';
      position: absolute;
      left: -10px;
    }
  }
}