.hamburger-item {
  position: relative;
  background-color: #fff;
  color: #585858;
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
  padding: 5px 0 5px 20px;
  text-decoration: none;

  &::before {
    position: absolute;
    left: -8px;
    top: -3px;
    content: '';
    transform: skewX(-22.5deg);
    width: 15px;
    height: 110%;
    margin: 0 auto;
    background-color:#F0F0F0;
  }

  &__text {
    font-size: 1.6rem;

    &--ja {
      font-weight: bold;
    }

    &--en {
      font-family: 'Futura Medium Italic';
    }
  }
}