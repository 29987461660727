.card {
  position: relative;
  width: 330px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;

  &__title {
    height: 42px;
    color: #585858;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    box-shadow: 0px 3px 6px #00000029;
  }

  &__image {
    margin-top: 20px;
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  &__text {
    margin-top: 20px;
    color: #585858;
    font-size: 1.1rem;
    white-space: pre-wrap;
  }
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .card {
    width: 100%;
    height: auto;

    &__title {
      position: absolute;
      top: 20px;
      left: 0;
      height: 30px;
      font-size: 1.6rem;
      background-color: #fff;
      border-radius: 0 15px 15px 0;
    }

    &__text {
      padding: 0 20px;
      margin-top: 10px;
      font-size: 1.5rem;
    }
  }
}