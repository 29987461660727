.sp .footer {
  width: 100%;
  padding: 10px;
  background-color: #027FFF;

  &__inner {
    width: 100%;
    max-width: 375px;
    color: #fff;
    margin: auto;
  }

  &__name {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &--icon {
      width: 10%;
      max-width: 36px;
    }

    &--en {
      margin-left: 2%;
      font-size: 2.0rem;
      font-weight: bold;
      font-family: 'Futura Medium';
    }

    &--ja {
      font-size: 1.3rem;
      margin-left: auto;
    }
  }

  &__address {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;

    &__text {
      font-size: 1.3rem;
      margin-left: 15px;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  &__tel {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;

    &__text {
      font-size: 1.3rem;
      margin-left: 15px;
      text-decoration: none;

      &:first-of-type {
        margin-left: 0;
      }

      &:any-link {
        color: #fff;
      }
    }
  }

  &__mail {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;

    &__text {
      font-size: 1.3rem;
      text-decoration: none;

      &:any-link {
        color: #fff;
      }
    }
  }

  &__copyright {
    width: 100%;
    margin-top: 10px;
    text-align: center;
    font-size: 0.8rem;
  }
}