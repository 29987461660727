.pc .footer {
  width: 100%;
  padding: 10px 20px;
  background-color: #027FFF;

  &__inner {
    width: 100%;
    max-width: 990px;
    color: #fff;
    margin: auto;
  }

  &__name {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &--icon {
      width: 5%;
      max-width: 72px;
    }

    &--en {
      margin-left: 2%;
      font-size: 2.5rem;
      font-weight: bold;
    }

    &--ja {
      font-size: 1.5rem;
      margin-left: auto;
    }
  }

  &__address {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;

    &__text {
      font-size: 1.7rem;
      margin-left: 30px;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  &__tel {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;

    &__text {
      font-size: 1.3rem;
      margin-left: 30px;
      text-decoration: none;

      &:first-of-type {
        margin-left: 0;
      }

      &:any-link {
        color: #fff;
      }
    }
  }

  &__mail {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;

    &__text {
      font-size: 1.3rem;
      text-decoration: none;

      &:any-link {
        color: #fff;
      }
    }
  }

  &__copyright {
    width: 100%;
    text-align: end;
  }
}