.access {
  width: 100%;

  &__title {
    width: 70%;
    margin-top: 80px;
  }

  &__text {
    color: #585858;
    font-size: 2rem;
    font-weight: bold;
    margin: 20px 0 0 40px;
  }

  &__map {
    margin: 20px 20px 60px;
    width: calc(100% - 40px);
    height: min(500px, 100vw);
    
    &__content {
      border: none;
    }
  }
}

// 〜1024px
@media only screen and (max-width: 1024px) {
  .access {
    &__title {
      width: 100%;
      margin-top: 20px;
    }

    &__text {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 10px 0 0 20px;
    }

    &__map {
      height: auto;
    }
  }
}